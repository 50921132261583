




































import Vue from "vue";
import { Component, Ref } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import { BContainer, BRow, BCol } from "bootstrap-vue";
import FilterComponent from "@/components/FilterComponent.vue";
@Component({
  components: {
    BRow,
    BContainer,
    BCol,
    FilterComponent,
  },
})
export default class SubHeader extends Vue {
  displayTicketKosong = false;
  displayListTicket = true;
  // @Model("OnClickFilter")
  @Prop() titlePage!: string;
  @Prop() subTitlePage!: string;
  @Prop() subTitleSmallPage!: string;
  @Prop() filter!: boolean;
  @Prop() statusCategory!: boolean;
  @Prop() category!: string;
  @Prop() subCategory!: string;
  @Prop() iconTitle!: boolean;

  @Ref() filterElement!: FilterComponent;

  clickFilter() {
    this.filterElement.showFilter();
    document.body.classList.add("modal-open");
  }
}
