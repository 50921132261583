






























import Vue from "vue";
import Component from "vue-class-component";
// import { Prop } from "vue-property-decorator";
import { BRow, BCol } from "bootstrap-vue";
@Component({
  components: {
    BRow,
    BCol,
  },
})
export default class MenuFooter extends Vue {}
