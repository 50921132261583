































import Vue from "vue";
import { BRow, BCol } from "bootstrap-vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({
  components: {
    BRow,
    BCol,
  },
})
export default class CardTicket extends Vue {
  @Prop() title!: string;
  @Prop() subTitle!: string;
  @Prop() date!: string;
  @Prop() time!: string;
  @Prop() status!: string;
  @Prop() isOpen!: string;
  @Prop() pageClose!: boolean;

  get colorStatus() {
    if (this.status === "new") {
      return "cl-red";
    } else {
      return "cl-green-light";
    }
  }
}
