
































import Vue from "vue";
import { BRow, BCol } from "bootstrap-vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({
  components: {
    BRow,
    BCol,
  },
})
export default class Search extends Vue {
  @Prop() searchStatus!: boolean;
}
