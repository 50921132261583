






























































































import Vue from "vue";
import { BRow, BCol, BContainer } from "bootstrap-vue";
import Component from "vue-class-component";
@Component({
  components: {
    BRow,
    BCol,
    BContainer,
  },
})
export default class FilterComponent extends Vue {
  activeShow: boolean = false;
  selected = "A";
  category = [];
  fromDate: string | null = null;
  toDate: string | null = null;
  options = [
    { value: "A", text: "Option A", notEnabled: true },
    { value: "B", text: "Option B" },
    { value: "D", text: "Option C" },
  ];
  levels = [
    { value: "A", text: "Urgency Level", notEnabled: true },
    { value: "B", text: "Option B" },
    { value: "D", text: "Option C" },
  ];
  listCategory = [
    {
      name: "Pengajuan perbaikan",
      id: "1",
      name_image: [require("@/assets/img/category-1.svg")],
      sub_category: [
        {
          name_sub: "Sub Category 1",
          id_sub: "1",
        },
        {
          name_sub: "Sub Category 2",
          id_sub: "2",
        },
        {
          name_sub: "Sub Category 3",
          id_sub: "3",
        },
      ],
    },

    {
      name: "Store",
      id: "2",
      name_image: [require("@/assets/img/category-2.svg")],
      sub_category: [
        {
          name_sub: "Sub Category 4",
          id_sub: "1",
        },
        {
          name_sub: "Sub Category 5",
          id_sub: "2",
        },
        {
          name_sub: "Sub Category 6",
          id_sub: "3",
        },
      ],
    },
    {
      name: "Preventif",
      id: "3",
      name_image: [require("@/assets/img/category-3.svg")],
      sub_category: [
        {
          name_sub: "Sub Category 7",
          id_sub: "1",
        },
        {
          name_sub: "Sub Category 8",
          id_sub: "2",
        },
        {
          name_sub: "Sub Category 9",
          id_sub: "3",
        },
      ],
    },
    {
      name: "Project",
      id: "4",
      name_image: [require("@/assets/img/category-4.svg")],
      sub_category: [
        {
          name_sub: "Sub Category 1",
          id_sub: "1",
        },
        {
          name_sub: "Sub Category 2",
          id_sub: "2",
        },
        {
          name_sub: "Sub Category 3",
          id_sub: "3",
        },
      ],
    },
  ];
  hideFilter() {
    this.activeShow = false;
  }
  showFilter() {
    this.activeShow = true;
  }
  get activeFilter() {
    if (this.activeShow === true) {
      return "active";
    } else {
      return "";
    }
  }
}
